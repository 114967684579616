import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { injectIntl } from 'helpers'
import { FormattedMessage, Layout, Section, Container, Link } from 'components'

const About = () => {
  return (
    <Layout>
      <Section>
        <Container>
          <h1>
            <FormattedMessage id="aboutpage-title" />
          </h1>
          <p>
            <FormattedMessage id="aboutpage-description" />
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default injectIntl(About)
